<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-toolbar elevation="0" style="background: #f5f5f5" height="80px">
            <div>
              <p class="mb-0 text-h4 font-weight-bold">
                Mothersite Product Report
              </p>
            </div>
            <v-spacer></v-spacer>

            <v-menu
              v-model="menu1"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start_date"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  class="pt-5"
                  style="width: 50px"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="start_date"
                @input="onStartDateChange"
                prev-icon="mdi-skip-previous"
                next-icon="mdi-skip-next"
                class="mt-0"
                color="purple darken-2"
              ></v-date-picker>
            </v-menu>

            <v-menu
              v-model="menu2"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="end_date"
                  label="End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  class="pt-5"
                  style="width: 50px"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="end_date"
                :min="start_date"
                @input="onEndDateChange"
                prev-icon="mdi-skip-previous"
                next-icon="mdi-skip-next"
                class="mt-0"
                color="purple darken-2"
              ></v-date-picker>
            </v-menu>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="products"
            :search="search"
            class="elevation-1"
            style="background: #f5f5f5"
            :loading="loading"
            loading-text="Please wait..."
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <b>Product Id: </b> {{ item.product_id }} <br />
                <b>Product Name: </b> {{ item.product_name }} <br />
              </td>
            </template>
            <template v-slot:top>
              <v-toolbar elevation="2" style="background: #f5f5f5" class="mt-2">
                <v-spacer></v-spacer>
                <div>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    filled
                    rounded
                    dense
                    hide-details
                  ></v-text-field>
                </div>

                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn shaped text color="#FF7743" @click="viewInvoice(item)">
                Invoice
              </v-btn>
            </template>

            <template v-slot:footer>
              <hr />
              <div>
                <v-row>
                  <v-col cols="9"></v-col>
                  <v-col>
                    <v-card
                      class="mx-5 my-0"
                      style="background: #f5f5f5"
                      elevation="0"
                    >
                      <v-card-text class="text-right">
                        <p class="font-weight-bold mb-0">
                          Total Profit :
                          {{ reportInfo.total_profit }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </template>

            <v-spacer> </v-spacer>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import { VueCropper } from "vue-cropper";

export default {
  data: () => ({
    crap: false,

    snackbar: false,
    text: "",
    color: "",

    loading: true,

    headers: [
      { text: "Invoice ID", value: "invoice_id", sortable: false },
      { text: "Barcode", value: "product_barcode", sortable: false },
      { text: "Title", value: "product_name" },
      { text: "Color", value: "product_color", sortable: false },
      { text: "Size", value: "product_size", sortable: false },

      { text: "Total VAT", value: "total_vat", sortable: false },

      { text: "Purchase price", value: "purchase_price" },
      { text: "Selling Price", value: "selling_price", sortable: false },

      { text: "Unit price", value: "unit_price" },
      { text: "Quantity", value: "quantity", sortable: false },

      { text: "Unit Discount", value: "unit_discount", sortable: false },
      { text: "Total Discount", value: "total_discount", sortable: false },

      { text: "Profit", value: "profit", sortable: false },

      { text: "Details", value: "action", sortable: false },

      // { text: "", value: "data-table-expand" },
    ],
    products: [],
    contact: {},
    editedIndex: -1,
    allBrands: [],

    user: {},

    expanded: [],
    singleExpand: true,

    menu1: false,
    menu2: false,
    start_date: new Date().toISOString().substr(0, 10),
    end_date: new Date().toISOString().substr(0, 10),
    reportInfo: {},
    search: "",
  }),

  components: {
    VueCropper,
  },

  methods: {
    // GET report
    initialize() {
      this.loading = true;

      let payload = {
        start_date: this.start_date,
        end_date: this.end_date,
      };

      axios
        .post("productdetails/get_imported_products_report/", payload)
        .then((response) => {
          this.loading = false;
          if (response.data.success && response.data.info) {
            this.products = response.data.info;
            this.reportInfo.total_profit = response.data.total_profit;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // If start date changed , end date changed to empty
    onStartDateChange() {
      this.menu1 = false;
      this.end_date = "";
    },

    // If end date changed , call the API
    onEndDateChange() {
      // Make API call
      this.initialize();
      this.menu2 = false;
    },

    // View the invoice
    viewInvoice(item) {
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/orderinvoice_updated/`,
      });
      routeData.href = `/supportdashboard/pages/orderinvoice_updated/?id=${item.invoice_id}`;
      window.open(routeData.href, "_blank");
    },
  },
  created() {
    this.initialize();
    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>


<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

